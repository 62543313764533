@import "grid";
@import "~bootstrap/scss/bootstrap";

body {
  font-family: 'Gilroy', Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
}

.navbar {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e4e5f3;

  .dropup, .dropright, .dropdown, .dropleft {
    position: static;
  }

  .dropdown-toggle::after {
    display: none;
  }

  //.dropdown:hover .dropdown-menu {
  //  display: flex;
  //  //margin-top: 0; // remove the gap so it doesn't close
  //}

  //.nav-item:hover {
  //  .dropdown-toggle {
  //    &.active {
  //      background: #ffffff;
  //      color: #000000;
  //      border-bottom: 1px solid #ffffff;
  //    }
  //  }
  //}

  .dropdown-menu {
    width: 100%;
    top: 119px;
    position: absolute;
    border: 0;
    border-radius: 0;
    height: 80px;
    //background: rgba(228, 229, 243, 0.3);
    background: #f7f7fc;
    padding: 0;
    border-bottom: rgba(47, 56, 102, 0.08) solid 1px;

    &.show {
      display: flex;
    }

    .dropdown-item {
      text-align: center;
      padding: 2rem 1rem;

      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3866;
      border-left: rgba(47, 56, 102, 0.08) solid 1px;

      &:last-child {
        border-right: rgba(47, 56, 102, 0.08) solid 1px;
      }

      &:hover, &.active {
        background-color: #0c6adc;
        color: #ffffff;
      }
    }
  }
}

.nav-link, .navbar-brand {
  color: #000000;
  cursor: pointer;
}

.nav-link {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 120px;
  padding: 3rem 1rem 3rem 1rem !important;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .nav-link {
    padding: 3rem 0rem 3rem 1rem !important;
  }
}

.nav-link:hover, .nav-link.active {
  background: #ffffff;
  color: #0c6adc;
  border-bottom: 1px solid #0c6adc;
}

.nav-item.button-menu {
  padding: 28px;
  padding-right: 0px;


  p {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #2f3866;

    a {
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #2f3866;

      b {
        font-weight: 500;
      }
    }

    &.email {
      margin-top: 10px;

      a {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #2f3866;
      }
    }
  }

  .phone, .email {
    position: relative;

    .last {
      letter-spacing: 0.015em;
    }

    &::before {
      content: url("../img/phone.svg");
      color: #0c6adc;
      font-weight: bold;
      display: block;
      width: 1em;
      float: left;
      position: absolute;
      left: -17px;
      top: -5px;
    }
  }

  .email {

    &::before {
      content: url("../img/email.svg");
      color: #0c6adc;
      font-weight: bold;
      display: block;
      width: 1em;
      float: left;
      position: absolute;
      left: -17px;
      top: 1px;
    }
  }
}

.nav-link.btn-link {
  padding: 1rem 3.7rem !important;
}

.nav-link.btn-link:hover {
  border: 1px solid #0c6adc;
  text-decoration: none;
  color: #FFFFFF;
  background-color: #0c6adc;
  transition: background-color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.navbar-collapse {
  justify-content: flex-end;
  /*justify-content: flex-end;*/
}

.navbar-toggler {
  background: #fff !important;
}

.bg-dark {
  background-color: #343a40 !important
}

.bg-primary {
  background-color: #343a40 !important
}

.header {
  background: #fff;
}

.logo {
  height: 100%;
  width: 130px;
}

/* COMMON START */
.btn-link {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 195px;
  height: 50px;
  background: #ffffff;
  color: #0c6adc;
  border: 1px solid #0c6adc;
  text-align: center;
  border-radius: 0;
}

.btn-link:hover {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #0c6adc;
  transition: background-color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* COMMON END */


/* FOOTER START */
footer {
  padding-top: 90px;
  min-height: 550px;
  background-color: rgba(228, 229, 243, 0.21);
  border-top: solid 1px #e4e5f3;

  .footer-logo {
    width: 130px;
  }

  .copyright {
    opacity: 0.6;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3866;
  }

  ul {
    li {
      list-style: none;
    }
  }

  .menu {
    ul {
      li {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 25px;

        a {
          color: #2f3866;
        }
      }
    }
  }

  .contacts {
    ul {
      li {
        margin-bottom: 30px;

        h1 {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3866;
        }

        p.item {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #2f3866;
          margin: 0;
        }
      }
    }
  }
}

small.hidden {
  display: none;
}

/* FOOTER END */